import {get, put, post, del, postUserId, putUserId} from "./base_y";
import qs from "qs";

// 个人中心管理

// 获取个人信息功能列表
export function getPersonal(data){
    return get('/v3/yun/person/funcs',data)
}

// 上移下移个人功能
export function postPersonalUpDown(id,data){
    return post('/v3/yun/person/func/moveUpDown/' + id, qs.stringify(data))
}

// 新增个人功能
export function postPersonalAdd(data){
    return post('/v3/yun/person/func', data)
}

// 删除/编辑个人功能
export function putPersonalEditRemove(id,type,data){
    return put('/v3/yun/person/func?id='+ id +'&type='+ type, data)
}

// 热搜词管理

// 热搜词查询
export function getHotSearch(pageNo,pageNum,data)   {
    return get('/v3/yun/system/search?pageNo=' + pageNo + '&pageNum=' + pageNum + '&search=' + data)
}

// App首页功能管理

// 获取App首页功能
export function getEnableFunction(data) {
    return get('/v3/yun/moudle/funcs', data)
}

// 上移下移App首页功能
export function postEnableUpDown(id, data) {
    return post('/v3/yun/moudle/func/moveUpDown/' + id, qs.stringify(data))
}

// 修改App首页功能
export function putEnableUpdate(id, data) {
    return put('/v3/yun/moudle/func/' + id, data)
}

// 新增App首页功能
export function postEnableAdd(data) {
    return postUserId('/v3/yun/moudle/func',data)
}

// 删除App首页功能
export function deleteEnableRemove (id) {
    return del('/v3/yun/moudle/func/' + id)
}

// 社区首页功能管理

// 获取社区首页功能
export function getCommunity (data) {
    return get('/v3/yun/community/funcs', data)
}

// 新增社区首页功能
export function postCommunityAdd (data) {
    return post('/v3/yun/community/func', data)
}

// 上移下移社区首页功能
export function postCommunityUpDown (id, type) {
    return post('/v3/yun/community/func/moveUpDown/' + id, qs.stringify(type))
}

// 编辑/删除社区首页功能
export function putCommunityUpDelete (id, type, data) {
    return put('/v3/yun/community/func?id=' + id + '&type=' + type, data)
}

// H5链接设置

// 查询H5链接设置
export function getH5Link (id) {
    return get('/v3/yunHtmlUrl?hid=' + id)
}

// 新增H5链接
export function postH5LinkAdd (data) {
    return post('/v3/yunHtmlUrl', data)
}

// 删除H5链接
export function putH5LinkDelete (id) {
    return put('/v3/yunDelHtmlUrl/' + id)
}

// 修改H5链接
export function putH5LinkUpdate (id, data) {
    return put('/v3/yunUpdHtmlUrl/' + id, data)
}

// 意见反馈管理

// 查询意见反馈
export function postFeedback (status, id) {
    return get('/v3/yun/system/feedback?state=' + status + '&id=' + id)
}

// 回复意见反馈
export function putFeedbackReply(data) {
    return putUserId('/v3/yun/system/feedback', data)
}

// 用户投诉管理

// 处理用户投诉
export function putUserComplaintsHandle(data) {
    return putUserId('/v3/yun/system/complain', data)
}

// 查询用户投诉列表
export function getUserComplaints(status, id) {
    return get('/v3/yun/system/complain?state=' + status + '&id=' + id)
}

// banner广告管理

// 查询banner广告管理
export function getHomeBanner(isShow, id) {
    return get('/v3/yun/platform/banner?isShow=' + isShow + '&id=' + id)
}

// 上移下移banner广告管理
export function putHomeBannerUpDown(id, move) {
    return put('/v3/yun/platform/banner/' + id + '/' + move)
}

// 上架下架banner广告
export function putHomeBannerUpDownShelf(id) {
    return put('/v3/yun/platform/banner/' + id)
}

// 新增banner广告
export function postHomeBannerAdd (data) {
    return postUserId('/v3/yun/platform/banner', data)
}

// 修改banner广告
export function putHomeBannerEdit (data) {
    return putUserId('/v3/yun/platform/banner', data)
}

// 获取推广banner广告
export function getHomeBannerExtension (id) {
    return get('/v3/yun/promote/banner/' + id)
}

// 获取banner首页推广
export function getHomeBannerIndexExtension (code) {
    return get('/v3/yun/promotes', {code})
}

// 开启banner推广
export function postHomeBannerOpenExtension (data) {
    return post('/v3/yun/promote/banner', data)
}

// 关闭banner推广
export function DelHomeBannerCloseExtension (id, data) {
    return del('/v3/yun/promote/' + id, data)
}